<template>
  <div>
    <!--    <div class="timer">timer: {{ timer }}</div>-->
    <Transition name="spinner">
      <div v-if="show" class="spinner-container" :class="position">
        <div class="spinner__spinner spinner"></div>
        <div class="spinner__text">{{ $t("label.loading") }}...</div>
      </div>
    </Transition>
  </div>
</template>
<style lang="scss" scoped>
.spinner-enter-active,
.spinner-leave-active {
  transition: opacity 0.25s ease;
}

.spinner-enter-from,
.spinner-leave-to {
  opacity: 0;
}

.timer {
  position: fixed;
  right: 30px;
  top: 30px;
  background: red;
  z-index: 20000;
}

.spinner-container {
  position: relative;
  z-index: 100000;
  pointer-events: none;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: 0.4rem 0.6rem;
  border-top-left-radius: 3px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  background: var(--app-navitem-active-ground);
  color: var(--white);

  .spinner__spinner {
    opacity: 0.8;
  }

  &.fixed {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
</style>
<script>
import { useNavStore } from "@/stores";
import { computed, ref, toRefs, watch } from "vue";

export default {
  setup(props) {
    let timerInterval;
    let stopTimeout;
    let show = ref(false);
    let timer = ref(0);

    const { isApp } = toRefs(props);

    const spinnerState = computed(() => {
      return isApp.value ? useNavStore().getAppSpinner : true;
    });
    const position = computed(() => {
      return isApp.value ? "fixed" : false;
    });

    const stopTimer = () => {
      clearInterval(timerInterval);
      timer.value = 0;

      clearInterval(stopTimeout);
      stopTimeout = setTimeout(() => {
        show.value = false;
      }, 500);
    };

    const startTimer = () => {
      clearInterval(stopTimeout);
      timerInterval = setInterval(() => {
        timer.value = timer.value + 100;
        if (timer.value > 100) {
          show.value = true;
        }
      }, 10);
    };

    watch(
      spinnerState,
      (state) => {
        state ? startTimer() : stopTimer();
      },
      { immediate: true }
    );

    return {
      show,
      position,
      timer,
      spinnerState,
    };
  },
  props: {
    isApp: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
