<template>
  <div class="app-overlay"></div>
</template>
<style lang="scss" scoped>
.app-overlay {
  background: var(--app-ground);
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1100;
  opacity: 0.8;
}
</style>
