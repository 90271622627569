<template>
  <div>
    <div id="mobileAlert" v-if="isMobile">
      <SurfaceCard>
        <img :src="getAppImage('logo.svg')" :alt="companyName" class="mb-3 max-w-10rem h-auto" />
        <IntroText :text="$t('message.mobile_alert', { appTitle: appTitle })" class="mb-0" />
      </SurfaceCard>
    </div>
  </div>
</template>
<script>
import { useBreakpointStore } from "@/stores";
import IntroText from "@/components/IntroText.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getAppCompany, getAppImage, getAppTitle } from "@/helpers";

export default {
  setup() {
    const breakpointStore = useBreakpointStore();
    const companyName = getAppCompany();
    const appTitle = getAppTitle();

    return { breakpointStore, companyName, appTitle };
  },
  components: {
    IntroText,
    SurfaceCard,
  },
  data() {
    return {
      blockedPanel: true,
      bodyEl: document.querySelector("body"),
    };
  },
  computed: {
    isMobile() {
      if (this.breakpointStore.type == "xs" || this.breakpointStore.type == "sm" || this.breakpointStore.type == "md") {
        disableBodyScroll(this.bodyEl);
        return true;
      }
      enableBodyScroll(this.bodyEl);
      return false;
    },
  },
  methods: {
    getAppImage,
  },
};
</script>

<style lang="scss" scoped>
#mobileAlert {
  background: var(--app-ground);
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20000;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .surface-card {
    max-width: 480px;
    padding: 40px;
    text-align: center;
  }
}
</style>
