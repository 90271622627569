import { onMounted, onUnmounted, ref } from "vue";
import { useBreakpointStore } from "@/stores";
export function useBreakpoints() {
  const breakpoints = ["xs", "sm", "md", "lg", "xl", "xxl"];
  let windowWidth = ref(window.innerWidth);

  const onWidthChange = () => {
    const width = window.innerWidth;
    windowWidth.value = width;

    let type = "xs";
    breakpoints.forEach((breakpoint) => {
      const size = Number(
        getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-${breakpoint}`).slice(0, -2)
      );
      if (windowWidth.value >= size) {
        type = breakpoint;
      }
    });

    const breakpointStore = useBreakpointStore();
    breakpointStore.save({ width, type });
  };
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));
  onWidthChange();
}
