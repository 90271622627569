import { createApp } from "vue";
import { createPinia } from "pinia";

import { router, i18n, gtm, sentry, primevue, dynamicTheme, storageEncryption } from "@/helpers";

import "@/assets/scss/main.scss";
import "@/assets/scss/theme/themes/default/theme.scss";

import App from "./App.vue";
import { lazyload } from "@/helpers";
import { useAppStore } from "@/stores/app.store";
const app = createApp(App);

app.use(createPinia());

useAppStore().setAppConfig();
useAppStore().setLang();

dynamicTheme();
storageEncryption();

app.use(router);
app.use(i18n);
app.use(gtm);

// LazyLoad
lazyload(app);

// PrimeVue
primevue(app);

// Sentry
sentry(app, router);

app.mount("#app");
